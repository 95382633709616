/* LaudoView.css */

.laudo-view-container {
  /* position: relative; */
  width: 100%;
  height: 1000px; /* Altura desejada do contêiner */
  border: none;
}


.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.floating-button-container {
  background-color: #3f51b5;
  display: 'flex';
  justifyContent: 'flex-end'
}

.floating-button {
  background-color: var(--primary)  !important; /* Adiciona !important para aumentar a especificidade */
  color: white !important; /* Ajusta a cor do texto */
  float: 'right'
 
}
